import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import CaretTop from "@/views/components/Icon/CaretTop.vue";
import CaretBottom from "@/views/components/Icon/CaretBottom.vue";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
import { API_CALL_STATUS } from "@/constant/config";
import userTransferingApi from "@/api/common/userTransferingApi";

export default {

    components: {
        CaretTop,
        CaretBottom,
        ColumnBalance: lazyLoadComponent({
            componentFactory: () => import("@/views/components/column/Balance.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBoxWithoutLoading,
        }),
        userTransferingDetail: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/gpuhubSync/UserTransferingDetail"),
            loading: SkeletonBoxWithoutLoading,
        }),
        StepTemplate: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/gpuhubSync/StepTemplate"),
            loading: SkeletonBoxWithoutLoading,
        }),
    },
    extends: baseComponent,
    data() {
        return {
            isCheckAll: false,
            listData: [],
            isLoading: false,
            allStepCount: null,
            realtimeTransferingByStep: {},
            currentStep: 1,
            pagination: {
                status: 1,
                querySearch: '',
                orderBy: 'u.user_name',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 50,
                outRowsNumber: 0,
            },
            autoLoadInterval: null,
        };
    },

    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            listStaff: state => state.staffs,
            decentralization: state => state.auth.decentralization,
        })
    },

    created() {
        EventBus.$on("openPopup", this.openPopup);
        EventBus.$on("closePopup", this.closePopup);
        this.getData();
    },

    mounted() {
        this.getTransferingAllStepCount();
        this.getRealtimeTransferingByStep(1);
        this.autoLoadInterval = setInterval(() => {
            this.getTransferingAllStepCount();
            this.getRealtimeTransferingByStep(this.currentStep);
            //this.getData();
        }, 10000);
    },
    beforeDestroy() {
        if (this.autoLoadInterval) {
            clearInterval(this.autoLoadInterval);
        }
    },
    methods: {
        getTransferingAllStepCount() {
            userTransferingApi.getTransferingAllStepCount().then((res) => {
                try {
                    if (res.data.result === 0) {
                        this.$set(this, 'allStepCount', res.data.data);
                    }
                } catch (error) {
                    console.log("getTransferingAllStepCount -> error", error);
                }
            }).catch(error => {
                console.log("getTransferingAllStepCount -> error", error);
            });
        },
        getRealtimeTransferingByStep(step) {
            this.currentStep = step;
            userTransferingApi.getRealtimeTransferingByStep(step).then((res) => {
                try {
                    if (res.data.result === 0) {
                        this.$set(this.realtimeTransferingByStep, `Step_${step}`, res.data.data.map(x => {
                            if (x.CONTENT && (step === 1 || step === 4)) {
                                x.CONTENT = JSON.parse(x.CONTENT);
                                x.folderCount = x.CONTENT.filter(x => x.IsFolder).length;
                            }                            
                            return x;
                        }));
                    }
                } catch (error) {
                    console.log("getRealtimeTransferingByStep -> error", error);
                }
            }).catch(error => {
                console.log("getRealtimeTransferingByStep -> error", error);
            });
        },
        tabClick(tabItem) {
            this.getRealtimeTransferingByStep(parseInt(tabItem.index) + 1);
        },
        getData() {
            this.isLoading = true;
            userTransferingApi.getDataView({
                querySearch: this.pagination.querySearch,
                pageIndex: this.pagination.pageIndex,
                pageSize: this.pagination.pageSize,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
            }).then((res) => {
                try {
                    if (res.data.result === 0) {
                        this.$set(this, 'listData', res.data.data.data);
                        this.$set(this, 'pagination', {
                            ...this.pagination,
                            status: 1,
                            orderBy: res.data.data.pagingItem.orderBy,
                            directionSort: res.data.data.pagingItem.directionSort,
                            pageIndex: res.data.data.pagingItem.pageIndex,
                            pageSize: res.data.data.pagingItem.pageSize,
                            outRowsNumber: res.data.data.pagingItem.outRowsNumber,
                        });
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    }
                } catch (error) {
                    console.log("getDataView -> error", error);
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log("getDataView -> error", error);
                this.feedStatusLoading(API_CALL_STATUS.FAILED);
                this.isLoading = false;
            });
        },



        // Selected all item
        selectedAll() {
            this.listData.forEach(element => {
                element.isChecked = this.isCheckAll;
            });
        },

        // Selected item
        selectedItem() {
            if (this.listData.filter(x => x.isChecked).length === this.listData.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },
        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    //this.getData(this.pagination);
                    this.getRealtimeTransferingByStep(this.currentStep);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
                case 'add':
                    this.addItem();
                    break;
                case 'delete':
                    this.deleteItem(param.data.id);
                    break;
            }
        },
        // Search list
        searchList() {
            this.pagination.status = 0;
            if (this.pagination.querySearch) {
                this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
            }
            this.getData();
        },
    }
}