import axios from "axios";
const queryString = require('query-string');
import { userTransferingCnf } from "@/constant/config";
export default {
    getDataView(requestData) {
        let queryParams = queryString.stringify(requestData);
        return axios({
            method: "GET",
            url: `${userTransferingCnf.getDataView}?${queryParams}`,
            withCredentials: true,
        });
    },
    getUserTransferingDetail(userId) {
        return axios({
            method: "GET",
            url: `${userTransferingCnf.getUserTransferingDetail}/${userId}`,
            withCredentials: true,
        });
    },
    getTransferingAllStepCount() {
        return axios({
            method: "GET",
            url: userTransferingCnf.getTransferingAllStepCount,
            withCredentials: true,
        });
    },
    getRealtimeTransferingByStep(step) {
        return axios({
            method: "GET",
            url: `${userTransferingCnf.getRealtimeTransferingByStep}/${step}`,
            withCredentials: true,
        });
    },
}